import React from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import FormSuccessBackground from "Assets/awards/submit-design.png";
import { Card, Grid, Box, IconButton, Container } from "@mui/material";

const style = {
  // overflow:"scroll",
  bgcolor: "background.paper",
  // borderRadius: "30px",
  p: 4,
  backgroundImage: `url(${FormSuccessBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
};

const AwardThankYouPage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" className="py-20 md:py-48">
      <Card>
        {/* <Box sx={style}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
          ></Grid>
          <Grid
            container
            className="py-32"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <h3 className="text-4xl" style={{ textAlign: "center" }}>
                {t("awardForm.successMessage3")}
              </h3>
              <h3
                className="mt-3 text-4xl"
                style={{ maxWidth: "300px", textAlign: "center" }}
              >
                {t("awardForm.successMessage1")}
              </h3>
              <h3 style={{maxWidth:"300px", textAlign:"center"}}>{t("awardForm.successMessage2")}</h3>
            </Grid>
          </Grid>
        </Box> */}
        <img src={FormSuccessBackground} alt="" />
      </Card>
    </Container>
  );
};

export default AwardThankYouPage;
