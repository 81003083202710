import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
  getCountries,
} from "react-phone-number-input";
import React from "react";
import { Controller } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import translateRHFErrorMsg from "../../../Helpers/TranslateRHFErrorMsg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { data } from "Constants/countries";
export default function InputController({
  name,
  control,
  rules,
  defaultValue = "",
  ref,
  errors,
}) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <>
            <div
              style={{
                border: "solid 1px #cbcbcb",
                padding: "7px 0",
                borderRadius: "3px",
              }}
            >
              <PhoneInput
                value={value}
                name={name}
                autocomplete="tel-national"
                onChange={onChange}
                addInternationalOption={false}
                countries={getCountries().filter((c) =>
                  ["BH", "SA", "KW", "OM", "QA", "AE"].includes(c)
                )}
                labels={data}
                flagComponent={({ country }) => (
                  <div className="flex bg-white">
                    <ArrowDropDownIcon fontSize="small" /> +
                    {getCountryCallingCode(country)}
                  </div>
                )}
                countrySelectProps={{ className: "bg-white" }}
                defaultCountry="SA"
              />
            </div>
            {Boolean(error) ? (
              <FormHelperText error>
                {translateRHFErrorMsg(error)}
              </FormHelperText>
            ) : null}
          </>
        );
      }}
    />
  );
}
