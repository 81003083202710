import { useFormContext, Controller } from "react-hook-form";
import { Autocomplete, TextField } from "@mui/material";
import translateRHFErrorMsg from "../../../Helpers/TranslateRHFErrorMsg";

export default function AutoCompleteController({ name, label, control, options }) {
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          size="small"
          freeSolo
          onChange={(event, item) => {
            field.onChange(item);
          }}
          getOptionLabel={(option) => option?.name}
          options = {options}
          renderInput={(params) => (
            <TextField
              label={label}
              {...params}
              error={!!error}
              helperText={translateRHFErrorMsg(error)}
            />
          )}
        />
      )}
    />
  );
}
